import { createBrowserRouter } from "react-router-dom";
import { LoginCallback } from "@okta/okta-react";
import { App } from './App';
import { Dashboard } from "./pages/dashboard/Dashboard";
import { ErrorPage } from "./components/ErrorPage";
import { Login } from "./pages/auth/Login";
import { Logout } from './pages/auth/Logout';
import { LoggedOut } from "./pages/auth/LoggedOut";
import { Users } from "./pages/users/Users";
import { UserDetails } from "./pages/users/UserDetails";
import { Approvals } from "./pages/approvals/Approvals";
import { ApprovalDetails } from "./pages/approvals/ApprovalDetails";
import { PendingDatabases } from "./pages/pendingdatabases/PendingDatabases";
import { PendingDatabaseDetails } from "./pages/pendingdatabases/PendingDatabaseDetails";
import { MyAccess } from "./pages/access/MyAccess";
import { RequestNewAccess } from "./pages/access/RequestNewAccess";
import { TeamAccess } from "./pages/access/TeamAccess";
import { TeamAccessDetails } from "./pages/access/TeamAccessDetails";
import { ResourceAccess } from "./pages/access/ResourceAccess";
import { Nexus } from "./pages/nexus/Nexus";
import { Databases } from "./pages/databases/Databases";
import { DatabaseDetails } from "./pages/databases/DatabaseDetails";
import { RequestDatabase } from "./pages/databases/RequestDatabase";
import { Instances } from "./pages/instances/Instances";
import { InstanceDetails } from "./pages/instances/InstanceDetails";
import { Backups } from "./pages/backups/Backups";
import { BackupDetails } from "./pages/backups/BackupDetails";
import { Deployments } from "./pages/deployments/Deployments";
import { DeploymentDetails } from "./pages/deployments/DeploymentDetails";
import { Clusters } from "./pages/deployments/Clusters";
import { Teams } from "./pages/teams/Teams";
import { TeamDetails } from "./pages/teams/TeamDetails";
import { ApiKeys } from "./pages/kong/ApiKeys";
import { Consumers } from "./pages/kong/Consumers";
import { ConsumerDetails } from "./pages/kong/ConsumerDetails";
import { NewConsumer } from "./pages/kong/NewConsumer";
import { GitHubActions } from "./pages/gha/GitHubActions";
import { Namespaces } from "./pages/namespaces/Namespaces";
import { EditNamespace } from "./pages/namespaces/EditNamespace";
import { CreateNamespace } from "./pages/namespaces/CreateNamespace";
import { AppBuilderController } from "./pages/apps/builder/AppBuilderController";
import { AppBuilderIntro } from "./pages/apps/AppBuilderIntro";
import { AppBuilderConvert } from "./pages/apps/convert/AppBuilderConvert";
import { Apps } from "./pages/apps/Apps";
import { AppDetails } from "./pages/apps/AppDetails";

export const router = createBrowserRouter([
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/login/callback",
      element: <LoginCallback />,
    },
    {
      path: "/logout",
      element: <Logout />,
    },
    {
      path: "/logout/success",
      element: <LoggedOut />,
    },
    {
      path: "/",
      element: <App />,
      errorElement: <ErrorPage />,
      children: [
        {
          errorElement: <ErrorPage />,
          children: [
            {
              index: true,
              element: <Dashboard />,
            },
            {
              path: "access",
              children: [
                {
                  index: true,
                  element: <MyAccess />,
                },
                {
                  path: "nexus",
                  element: <Nexus />,
                },
                {
                  path: "team",
                  children: [
                    {
                      index: true,
                      element: <TeamAccess />,
                    },
                    {
                      path: "details/:id",
                      element: <TeamAccessDetails />,
                    },
                  ],
                },
                {
                  path: "resource",
                  children: [
                    {
                      index: true,
                      element: <ResourceAccess />,
                    },
                    {
                      path: "details/:id",
                      element: <TeamAccessDetails />,
                    },
                  ],
                },
                {
                  path: "new",
                  element: <RequestNewAccess />,
                },
              ],
            },
            {
              path: "databases",
              children: [
                {
                  index: true,
                  element: <Databases />,
                },
                {
                  path: "details/:id",
                  element: <DatabaseDetails />,
                },
                {
                  path: "request",
                  element: <RequestDatabase />,
                },
              ],
            },
            {
              path: "instances",
              children: [
                {
                  index: true,
                  element: <Instances />,
                },
                {
                  path: "details/:id",
                  element: <InstanceDetails />,
                },
              ],
            },
            {
              path: "backups",
              children: [
                {
                  index: true,
                  element: <Backups />,
                },
                {
                  path: "details/:id",
                  element: <BackupDetails />,
                },
              ],
            },
            {
              path: "apps",
              children: [
                {
                  index: true,
                  element: <Apps />,
                },
                {
                  path: "details/:id",
                  element: <AppDetails />,
                },
                {
                  path: "builder",
                  children: [
                    {
                      index: true,
                      element: <AppBuilderIntro />,
                    },
                    {
                      path: "start",
                      element: <AppBuilderController />,
                    },
                    {
                      path: "convert",
                      element: <AppBuilderConvert />,
                    },
                  ],
                },
              ],
            },
            {
              path: "deployments",
              children: [
                {
                  index: true,
                  element: <Deployments />,
                },
                {
                  path: "details/:id",
                  element: <DeploymentDetails />,
                },
              ],
            },
            {
              path: "clusters",
              element: <Clusters />,
            },
            {
              path: "users",
              children: [
                {
                  index: true,
                  element: <Users />,
                },
                {
                  path: "details/:id",
                  children: [
                    {
                      index: true,
                      element: <UserDetails />,
                    },
                  ],
                },
              ],
            },
            {
              path: "teams",
              children: [
                {
                  index: true,
                  element: <Teams />,
                },
                {
                  path: "details/:id",
                  children: [
                    {
                      index: true,
                      element: <TeamDetails />,
                    },
                  ],
                },
              ],
            },
            {
              path: "admin",
              children: [
                {
                  path: "approvals",
                  children: [
                    {
                      index: true,
                      element: <Approvals />,
                    },
                    {
                      path: "details/:id",
                      element: <ApprovalDetails />,
                    },
                  ],
                },
                {
                  path: "pending-databases",
                  children: [
                    {
                      index: true,
                      element: <PendingDatabases />,
                    },
                    {
                      path: "details/:id",
                      element: <PendingDatabaseDetails />,
                    },
                  ],
                },
              ],
            },
            {
              path: "kong",
              children: [
                {
                  path: "api-keys",
                  children: [
                    {
                      index: true,
                      element: <ApiKeys />,
                    },
                  ],
                },
                {
                  path: "consumers",
                  children: [
                    {
                      index: true,
                      element: <Consumers />,
                    },
                    {
                      path: "new",
                      element: <NewConsumer />,
                    },
                    {
                      path: "details/:id",
                      element: <ConsumerDetails />,
                    },
                  ],
                },
              ],
            },
            {
              path: "namespaces",
              children: [
                {
                  index: true,
                  element: <Namespaces />,
                },
                {
                  path: "details/:id",
                  element: <EditNamespace />,
                },
                {
                  path: "new",
                  element: <CreateNamespace />,
                },
              ],
            },
            {
              path: "gha",
              element: <GitHubActions />,
            },
          ],
        }
      ],
    },
]);
