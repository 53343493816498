export const config = {
  topsServiceApiUrl: process.env.REACT_APP_TOPS_SERVICE_API_URL,
  orgChartApiUrl: process.env.REACT_APP_ORG_CHART_API_URL,
  vaultAccessProviderApiUrl: process.env.REACT_APP_VAULT_ACCESS_PROVIDER_API_URL,
  dbAccessProviderApiUrl: process.env.REACT_APP_DB_ACCESS_PROVIDER_API_URL,
  approvalEngineApiUrl: process.env.REACT_APP_APPROVAL_ENGINE_API_URL,
  databaseBuilderApiUrl: process.env.REACT_APP_DATABASE_BUILDER_API_URL,
  ghaQueueApiUrl: process.env.REACT_APP_GHA_QUEUE_API_URL,
  apiKeyManagementApiUrl: process.env.REACT_APP_APIKEY_MANAGEMENT_API_URL,
  apiKeyPortalApiUrl: process.env.REACT_APP_API_KEY_PORTAL_API_URL,
  environment: process.env.REACT_APP_ENVIRONMENT,
  okta: {
    pkce: true,
    issuer: process.env.REACT_APP_OKTA_ISSUER,
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
    redirectUri: window.location.origin + '/login/callback',
    postLogoutRedirectUri: window.location.origin + '/logout/success',
    scopes: ['openid', 'profile', 'email', 'offline_access'],
    tokenManager: {
      autoRenew: true
    }
  }
}
