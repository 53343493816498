import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { topsServiceApi } from './tops-service'
import { orgChartApi } from './org-chart'
import { vaultAccessProviderApi } from './vault-access-provider'
import { dbAccessProviderApi } from './db-access-provider'
import { approvalEngineApi } from './approval-engine'
import { databaseBuilderApi } from './database-builder'
import { ghaQueueApi } from './gha-queue'
import { apiKeyPortalApi } from './api-key-portal'
import { errorHandler } from './error-handler'
import authReducer from './authSlice'

export const store = configureStore({
  reducer: {
    [topsServiceApi.reducerPath]: topsServiceApi.reducer,
    [orgChartApi.reducerPath]: orgChartApi.reducer,
    [vaultAccessProviderApi.reducerPath]: vaultAccessProviderApi.reducer,
    [dbAccessProviderApi.reducerPath]: dbAccessProviderApi.reducer,
    [approvalEngineApi.reducerPath]: approvalEngineApi.reducer,
    [databaseBuilderApi.reducerPath]: databaseBuilderApi.reducer,
    [ghaQueueApi.reducerPath]: ghaQueueApi.reducer,
    [apiKeyPortalApi.reducerPath]: apiKeyPortalApi.reducer,
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(errorHandler)
      .concat(topsServiceApi.middleware)
      .concat(orgChartApi.middleware)
      .concat(vaultAccessProviderApi.middleware)
      .concat(dbAccessProviderApi.middleware)
      .concat(approvalEngineApi.middleware)
      .concat(databaseBuilderApi.middleware)
      .concat(ghaQueueApi.middleware)
      .concat(apiKeyPortalApi.middleware)
})

// required for refetchOnFocus/refetchOnReconnect behaviors in RTK
setupListeners(store.dispatch)
